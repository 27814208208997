import Icon from '@/assets/icons/marker.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const pointOfInterestRoutes: RouterRecord = {
	path: '/poi',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/poi',
	meta: { title: 'poi', icon: Icon, group: 'modules', id: UserModuleIdentifiers.POI },
	name: RouteNames.POI_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/poi/PoiIndex.vue'),
			name: RouteNames.POI_DEFAULT,
			meta: { title: 'poi', icon: 'marker', root: true },
			children: [
				{
					path: 'locality/:locality_id(\\d+)',
					component: async () => import('@/views/poi/PoiIndex.vue'),
					name: RouteNames.POI_LOCALITY,
				},
			],
		},
		{
			path: 'locality/:locality_id(\\d+)/form',
			component: async () => import('@/views/poi/PoiFormRoot.vue'),
			name: RouteNames.POI_ADD_FORM,
			meta: { edit: false },
		},
		{
			path: 'create',
			component: async () => import('@/views/poi/PoiFormRoot.vue'),
			name: RouteNames.POI_ROOT_ADD_FORM,
			meta: { edit: false },
		},
		{
			path: ':poi_id',
			component: async () => import('@/views/poi/PoiFormRoot.vue'),
			name: RouteNames.POI_EDIT_FORM,
			meta: { edit: true },
		},
	],
};
